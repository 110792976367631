import { Box, Flex } from "@chakra-ui/react";
import styled from "@emotion/styled";
import { useEffect, useState } from "react";

export const StatusTag: React.FC<{
  children: any;
  type: "completed" | "warning" | "error" | "failure" | "queued" | "in_progress" | null;
  style?: any;
  hidden?: boolean;
}> = (props: any) => {
  const { type, children, style } = props;

  const [indicatorProps, setIndicatorProps] = useState({} as any);

  const indicatorColor = () => {
    if (type === "in_progress") {
      setIndicatorProps({
        indicator: "#05518C",
        text: "white",
        bg: "#0078C8",
      });
    }

    if (type === "completed" || type === "success") {
      setIndicatorProps({
        indicator: "#56CB62",
        text: "#166534",
        bg: "#DCFCE7",
      });
    }

    if (type === "error" || type === "failure") {
      setIndicatorProps({
        indicator: "#FEE2E2",
        text: "#FEE2E2",
        bg: "#B91C1C",
      });
    }

    if (type === "cancelled" || type === "warning") {
      setIndicatorProps({
        indicator: "#B91C1C",
        text: "#B91C1C",
        bg: "#FEE2E2",
      });
    }

    if (!type || type === "queued") {
      setIndicatorProps({
        indicator: "black",
        text: "black",
        bg: "#aaa8b0",
      });
    }
  };

  useEffect(() => {
    indicatorColor();
  }, [type]);

  return (
    <BaseTag
      pb="5px"
      hidden={props.hidden}
      color={indicatorProps.text}
      bg={indicatorProps.bg}
      width="100px"
      textTransform="capitalize"
    >
      <Flex flexDirection="row" alignItems={"center"} gap={1}>
        <Box
          {...style}
          bg={indicatorProps.indicator}
          w="8px"
          h="8px"
          borderRadius="50%"
        ></Box>{" "}
        {!children ? "Pending" : children}
      </Flex>
    </BaseTag>
  );
};

const BaseTag = styled(Box)({
  borderRadius: "100px",
  padding: "4px 8px 4px 8px",
  fontWeight: 600,
  fontSize: "12px",
});
