// Accepts ISO format returns after prettified
// quick and dirty
export const formatDate = (date: Date | string | undefined) => {
  if (!date) return null;
  const convertedDate = new Date(date); // Mon Apr 11 2011 20:20:30 GMT+1000
  const parts = convertedDate.toString().split(" ");
  const timeParts = parts[4].split(":");
  const fix = +timeParts[0] >= 12 ? "PM" : "AM";

  return `${parts[2]} ${parts[1]} ${parts[3]}, ${timeParts[0]}:${timeParts[1]} ${fix}`;
};

export const getMonthNumber = (month: string) => {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  
  return months.indexOf(month) + 1;
};
