import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IntegrationItem } from "../../../models/integration-item.model";

export interface WorkflowState {
  integrationItems: IntegrationItem[];
}

// Update initial state
export const initialState: WorkflowState = {
  integrationItems: [],
};

export const workflowSlice = createSlice({
  name: "workflow",
  initialState,
  reducers: {
    // Add a new integration item
    addIntegrationItem: (state, action: PayloadAction<IntegrationItem>) => {
      state.integrationItems.push(action.payload);
    },
    
    // Update an existing integration item
    updateIntegrationItem: (state, action: PayloadAction<IntegrationItem>) => {
      const index = state.integrationItems.findIndex(item => item.id === action.payload.id);
      if (index > -1) {
        state.integrationItems[index] = action.payload;
      }
    },
    
    // Remove an integration item
    removeIntegrationItem: (state, action: PayloadAction<string>) => {
      state.integrationItems = state.integrationItems.filter(item => item.id !== action.payload);
    },
    
    // Set all integration items
    setIntegrationItems: (state, action: PayloadAction<IntegrationItem[]>) => {
      state.integrationItems = action.payload;
    },
  },
});

// Export actions
export const { 
  addIntegrationItem,
  updateIntegrationItem,
  removeIntegrationItem,
  setIntegrationItems,
} = workflowSlice.actions;

export default workflowSlice.reducer;
