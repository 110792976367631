import {
  Flex,
  Image,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import Layout from "../../components/app/Layout";
import BaseContainer from "../../components/form/BaseContainer";
import { formatDate } from "../../utils/date.util";
import { ArrowRightIcon, RepeatIcon } from "@chakra-ui/icons";
import { IntegrationService } from "../../service/integration.service";
import { IntegrationItem } from "../../models/integration-item.model";
import { StatusTag } from "../../components/shared/StatusTag";
import { Loading } from "../../components/shared/Loading";
import { errorToast, successToast } from "../../constants/toast.constants";
import { useNavigate } from "react-router-dom";
import { WorkflowTriggerModal } from "../../components/modal/WorkflowTriggerModal";
import { delay } from "../../utils/form.util";
import { useSelector } from "react-redux";
import { RootState, store } from "../../store";
import { setIntegrationItems, updateIntegrationItem } from "../../store/slice/Workflows/workflow.slice";
import { Icons } from "../../components/shared/Icons";

const baseContainerStyles = {
  mx: "auto",
  padding: "0",
  flexGrow: "1",
  borderRadius: { base: "0px", lg: "8px 8px 0 0" },
  minWidth: "95%",
  minH: { base: "calc(100vh - 80px - 24px)" },
};

const integrationService = new IntegrationService();

export const Integrations: React.FC<any> = (props: any) => {
  const [integrations, setIntegrations] = useState([] as IntegrationItem[]);
  const [loading, setLoading] = useState(true);
  const [warningModalOpen, setWarningModalOpen] = useState(false);
  const [activeId, setActiveId] = useState("");
  const [integrationInProgress, setIntegrationInProgress] = useState(false);

  const toast = useToast();
  const navigate = useNavigate();
  const integrationWorkflows = useSelector((state: RootState) => state.workflows.integrationItems);

  const init = async () => {
    if (integrationWorkflows?.length) {
      setIntegrations(integrationWorkflows);
      const isRunning = integrationWorkflows.some(
        (integration) =>
          integration.lastRunStatus === "in_progress" ||
          integration.lastRunStatus === "queued"
      );
  
      setIntegrationInProgress(isRunning);
      setLoading(false);
      return;
    }
    
    setLoading(true);
    await setIntegrationData().finally(() => {
      setLoading(false);
      setWarningModalOpen(false);
    });
  };

  const setIntegrationData = async () => {
    const integrations = await integrationService.getWorkflowWithRuns();
    const isRunning = integrations.some(
      (integration) =>
        integration.lastRunStatus === "in_progress" ||
        integration.lastRunStatus === "queued"
    );

    setIntegrationInProgress(isRunning);
    setIntegrations(integrations);
    store.dispatch(setIntegrationItems(integrations));
  };

  const triggerWorkflow = async (month?: string) => {
    setLoading(true);
    setWarningModalOpen(false);
    await integrationService
      .triggerWorkflow(activeId, month)
      .then(async () => {
        toast(successToast("Integration started."));
        await delay(2500).then(async () => {
          setIntegrationInProgress(true);
          await setIntegrationData();
        });
      })
      .catch(() => {
        toast(errorToast("Integration failed to start."));
      }).finally(() => {
        setLoading(false);
      });
  };

  const onTriggerWorkflowClick = async (workflowId: string) => {
    setWarningModalOpen(true);
    setActiveId(workflowId);
  };

  useEffect(() => {
    if (integrationInProgress) {
      const pollTimer = setInterval(setIntegrationData, 60000);

      return () => clearInterval(pollTimer);
    }
  }, [integrationInProgress]);

  useEffect(() => {
    init();
  }, []);

  return (
    <Layout>
      <Loading loading={loading} />
      <Flex
        flexDirection="row"
        px="48px"
        pt="24px"
      >
        <Text fontSize="24px" fontWeight="bold">
          Active Integrations
        </Text>
        {/* <RepeatIcon
          cursor="pointer"
          w="36px"
          h="36px"
          _hover={{ color: "#29ab02" }}
          onClick={init}
        /> */}
      </Flex>
      <WorkflowTriggerModal
        isOpen={warningModalOpen}
        activeWorkflowId={activeId}
        onClose={() => {
          setWarningModalOpen(false);
          setActiveId("");
        }}
        onConfirm={triggerWorkflow}
      />
      <BaseContainer styles={baseContainerStyles}>
        <TableContainer>
          <Table size="md">
            <Thead>
              <Tr>
                <Th>Name</Th>
                <Th>Description</Th>
                <Th>Last Run</Th>
                <Th>Last Run Status</Th>
                <Th>Actions</Th>
              </Tr>
            </Thead>
            <Tbody>
              {/* these are workflows */}
              {integrations?.map((integration: IntegrationItem, i: number) => (
                <Tr key={i}>
                  <Td
                    fontWeight={600}
                    _hover={{ color: "#29ab02", cursor: "pointer" }}
                    onClick={() => navigate(`/history/${integration.id}`)}
                  >
                    {integration.name}
                  </Td>
                  <Td>{integration.description}</Td>
                  <Td>{formatDate(integration.lastRunDate)}</Td>
                  <Td>
                    {!!integration.lastRunStatus && (
                      <StatusTag
                        type={integration.lastRunStatus as any}
                        style={{ width: "120px" }}
                      >
                        {integration.lastRunStatus}
                      </StatusTag>
                    )}
                  </Td>
                  <Td>
                    <Flex flexDirection="row" justifyContent="center" gap="16px">
                      {!integration.lastRunStatus ||
                      integration.lastRunStatus === "completed" ? (
                        <Icons.Play
                          cursor="pointer"
                          fill="#B80E5E"
                          onClick={() => onTriggerWorkflowClick(integration.id)}
                        />
                      ) : (
                        <Spinner />
                      )}
                    </Flex>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </BaseContainer>
    </Layout>
  );
};
