import { WorkflowRun } from "../models/github/workflow-run.model";
import { Workflow } from "../models/github/workflow.model";
import { BaseService } from "./base.service";

export class IntegrationService extends BaseService {
  constructor() {
    super();
  }

  async triggerWorkflow(workflowId: string, runMonth?: string) {
    await this.client.post(`/integration/workflow/trigger`, { workflowId, runMonth });
  }
  
  async getWorkflows(): Promise<Workflow[]> {
    const resp = await this.client.get("/integration/workflow/list");
    return resp.data;
  }

  // only returns the first run of the workflow
  async getWorkflowWithRuns() {
    const resp = await this.client.get("/integration/workflow/list/runs");
    return resp.data;
  }

  async getWorkflowRunsHistory(workflowId: string): Promise<WorkflowRun[]> {
    const resp = await this.client.get(
      `/integration/workflow/runs/history?workflowId=${workflowId}`
    );
    return resp.data;
  }
}
