import {
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { ButtonCustom } from "../form/controls/ButtonCustom";
import { DropdownInput } from "../form/controls/DropdownInput";
import { getMonthNumber } from "../../utils/date.util";

interface ModalProps {
  isOpen: boolean;
  activeWorkflowId?: string;
  onClose?: () => void;
  onConfirm: (month?: string) => void;
}

export const WorkflowTriggerModal: React.FC<ModalProps> = (
  props: ModalProps
) => {
  const { isOpen, onClose, onConfirm, activeWorkflowId } = props;
  const [disabled, setDisabled] = useState(false);
  const [periodOptions, setPeriodOptions] = useState({});
  const [selectedMonth, setSelectedMonth] = useState(null);

  const shouldShowMonthSelect = activeWorkflowId && activeWorkflowId.toString() === "79355544";

  const handleConfirm = () => {
    setDisabled(true);

    if (selectedMonth) { 
      onConfirm(selectedMonth);
      return;
    }
    
    onConfirm();
  };

  const getPeriodOptions = () => {
    const currentDate = new Date();
    
    const options = {};
    for (let i = 0; i <= 3; i++) {
      const date = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth() - i,
        1
      );
      
      const label = date.toLocaleString("default", { month: "long" });
      options[label] = label;
    }

    return options;
  };

  useEffect(() => {
    const options = getPeriodOptions();
    setPeriodOptions(options);

    if (shouldShowMonthSelect) {
      setSelectedMonth(getMonthNumber(Object.keys(options)[0]));
    }

    setDisabled(false);
  }, [isOpen, activeWorkflowId]);

  const modalMonthSelect = (
    <>
      <DropdownInput
        emptyStart
        options={getPeriodOptions()}
        defaultValue={Object.keys(periodOptions)[0]}
        onChange={(e) => {
          const monthNumber = getMonthNumber(e.target.value);
          setSelectedMonth(monthNumber);
        }}
      />
    </>
  );

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalContent>
        <ModalHeader color="red">Confirmation</ModalHeader>

        <ModalBody>
          <div className="mb-4">
            Are you sure you want to re-run this integration? It cannot be
            stopped once started.
          </div>
          {shouldShowMonthSelect && modalMonthSelect}
        </ModalBody>
        <ModalFooter>
          <Flex gap="12px">
            <ButtonCustom secondary neutral onClick={onClose}>
              Cancel
            </ButtonCustom>
            <ButtonCustom onClick={handleConfirm} disabled={disabled}>
              Confirm Run
            </ButtonCustom>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
