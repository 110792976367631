export const Icons = {
  Play: ({ ...props }) => (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.5 5.66881C4.5 4.24268 6.029 3.33862 7.2786 4.0259L18.8192 10.3732C20.1144 11.0856 20.1144 12.9467 18.8192 13.659L7.2786 20.0064C6.029 20.6936 4.5 19.7896 4.5 18.3634V5.66881Z"
        fill="#0F172A"
        {...props}
      />
    </svg>
  ),
};
